document.addEventListener('DOMContentLoaded', () => {

    //投稿モーダル表示イベントの発火
    const _showModal = () => {
        window.dispatchEvent(new Event('showPost'));
    };

    //投稿内容復旧イベントの発火
    const _restorePost = id => {
        //グローバル変数に ID をセット
        window.restorePostId = id;

        //イベント発火
        window.dispatchEvent(new Event('restoreEvent'));
    };

    //指定された DOM の祖先中から、指定されたクラスを持っているものを返す
    const _findParent = (className, child) => {
        //自身が null  なら null を返す
        if (child === null) {
            return null;
        }

        //自身がクラスを持っていたら返す
        if (child.classList.contains(className)) {
            return child;
        }

        //自身が html 要素なら null　を返す
        if (child instanceof HTMLHtmlElement) {
            return null;
        }

        //そうでなければ親を検索
        return _findParent(className, child.parentElement);
    };

    //モーダル表示イベントを紐づける
    document.addEventListener('click', event => {
        //js-showPostButton クラスを持っている要素を自身＆祖先から検索
        const currentTarget = _findParent('js-showPostButton', event.target);

        //js-showPostButton クラスを持っている要素がなければ何もしない
        if ( ! currentTarget) {
            return;
        }

        //発火
        _showModal();

        //もし currentTarget が data-id を持っていたらその id の投稿内容を復旧させる
        if (currentTarget.dataset.id) {
            _restorePost(Number(currentTarget.dataset.id));
        }
    });

});